import { useQuery } from '@tanstack/react-query'
import { isFuture, addHours } from 'date-fns'

import { auth } from '../../config/firebase'
import type { TimeSlot } from '../../types/Booking'
import type { Patient } from '../../types/Patient'
import { zonedTimeToUtc } from 'date-fns-tz'

interface GetAppointmentSlotsProps {
  providerId: string
  date: string
  sessionDuration: number
  patient: Patient | Partial<Patient>
  hoursNotice?: number
  enabled?: boolean
}

export const useGetAppointmentSlots = (params: GetAppointmentSlotsProps) =>
  useQuery(
    [
      'getAppointmentSlots',
      params.providerId,
      params.date,
      params.sessionDuration,
    ],
    async () => getAppointmentSlots(params),
    {
      refetchOnMount: 'always',
      retry: 1,
      enabled: params.enabled,
    }
  )

const getAppointmentSlots = async ({
  date,
  providerId,
  sessionDuration,
  hoursNotice = 0,
  patient,
}: GetAppointmentSlotsProps): Promise<TimeSlot[]> => {
  if (!date || !providerId) return []

  const token: string = await auth.currentUser.getIdToken()
  const result = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_TIME_TRACKING_URL
    }v1/time-entries/available-slots?providerId=${providerId}&month=${date}&sessionDuration=${sessionDuration}&extraDaysAfter=10`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  if (!result?.length) return []

  return (
    result
      ?.map(
        (data: any): TimeSlot => ({
          startOriginal: new Date(data.startTime).toISOString(),
          endOriginal: new Date(data.endTime).toISOString(),
        })
      ) // sort ascending
      ?.sort((a: TimeSlot, b: TimeSlot) =>
        a.startOriginal > b.startOriginal ? 1 : -1
      )
      // no duplicates
      ?.filter(
        (slot: TimeSlot, i: number, arr: TimeSlot[]) =>
          arr.findIndex((s) => s.startOriginal === slot.startOriginal) === i
      )
      ?.filter((slot: TimeSlot) => {
        // in the future
        const zonedTime = zonedTimeToUtc(slot.startOriginal, patient.timeZone)

        return isFuture(addHours(zonedTime, -hoursNotice))
      })
  )
}
